@import "rsuite/dist/rsuite.css";

body {
  margin: 0;
  padding: 0;
  font-family: Inter, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #475666;
  background-color: #edf1f4;
}

.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon, .icon_header {
  margin-right: 5px;
}

.close_icon {
  color: red;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: 0.75fr 1fr 1fr 1fr;
  grid-template-areas:
  'sidebar main main main';
  height: 100vh;
}



/* ---------------------------------------------------------- */
/* Header  */
/* ---------------------------------------------------------- */
.header {
  background-color: rgb(19,40,72);
  color: #fff;
  grid-area: header;
  height: 60px;
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  /* box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35); */
}

.header-title{
  margin-top: 15px;
  font-size: 1.375rem;
  font-weight: 700;
  text-align: center;
  width: -webkit-fill-available;
}

.menu-icon {
  display: none;
}
/* ---------------------------------------------------------- */
/* End Header  */
/* ---------------------------------------------------------- */



/* ---------------------------------------------------------- */
/* Sidebar  */
/* ---------------------------------------------------------- */
#sidebar {
  grid-area: sidebar;
  height: 100%;
  background-color: rgb(19,40,72);
  color: #fff;
  overflow-y: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  z-index: 2000;
}

.sidebar-list-hover-title{
  color: #adadad;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-title {
  display: flex;
  align-items: center;
  padding: 15px 30px 15px 30px;
}

#top-bottom-border{
  height: 60px;
  border: 1px solid #2c3e5b;
  border-left: none;
  border-right: none;
}

.sidebar-title > span {
  display: none;
}

.sidebar-brand {
  margin-top: 15px;
  font-size: 1.375rem;
  font-weight: 700;
}

.sidebar-customer {
  font-size: 0.875rem;
  font-weight: 500;
}

.sidebar-list {
  padding: 10px;
  list-style-type: none;
}

.sidebar-list-dropdown {
  padding: 10px 0px 0px 0px;
  list-style-type: none;
}

.sidebar-list-item {
  padding: 7px 20px;
  font-size: 18px;
}

.sidebar-list-item:hover {
  cursor: pointer;
  color: white;
}

.sidebar-list-item > a {
  text-decoration: none;
  color: #adadad;
  font-weight: 600;
  /* font-size: 0.875rem; */
  font-size: 1rem;
}

.sidebar-page-active {
  background-color: #163a5e;
  border-radius: 0.5rem;
}

.sidebar-list-item  > ul{
  display: none;
}

.show-list-item-dropdown > ul{
  display: block;
}

.sidebar-page-active > a{
  color: white;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
  /*
    we want the z-index of the sidebar higher so that
    the charts are not showing over the sidebar 
    on small screens
  */
  z-index: 12 !important;
}
/* ---------------------------------------------------------- */
/* End Sidebar  */
/* ---------------------------------------------------------- */



/* ---------------------------------------------------------- */
/* Main  */  
/* ---------------------------------------------------------- */
.rs-picker-daterange > .rs-input-group.rs-input-group-inside .rs-input{
  font-weight: 400;
  font-size: 1rem;
  color: black;
}


.charts_filter_label{
  font-size: smaller;
  color: grey;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  background-color: #edf1f4;
  color: #475666;
}

.main-title {
  display: flex;
  justify-content: space-between;
  margin-left: 7px;
}

.main-title h2 {
  font-size: 1.6rem;
  font-weight: 700;
}

.main-accordion-body{
  margin: 20px 0px;
}

.main_conatiner_spinner{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.main_conv_card{
  box-shadow: 10px 15px 15px rgba(17,44,123,.1);
  height: 100% !important;
}

.main_conv_col{
  margin-top: 25px !important;
}

.main-conv-card-body-center{
  text-align: center;
}

.main-conv-card-body-left{
  text-align: left;
}

.enrich_bid_button{
  background-color: #304e7d !important;
  color: white !important;
  border: none !important;
  font-weight: 500 !important;
  box-shadow: 5px 5px 6px rgba(71,86,102,.5) !important;
}


.show_options_in_creative_btn{
  color: #304e7d !important;
  border: none !important;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem !important;
}

.groups_page_button{
  background-color: white !important;
  color: black !important;
  border: 1px solid rgb(142, 142, 142) !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.groups_show_groups_button{
  width: 6rem !important;
  background-color: #304e7d !important;
  color: white !important;
  border: 1px solid rgb(142, 142, 142) !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.selected_groups_page_button{
  width: 6rem !important;
  background-color: #304e7d !important;
  color: white !important;
  border: 1px solid rgb(142, 142, 142) !important;
  font-weight: 500 !important;
  box-shadow: 5px 5px 6px rgba(71,86,102,.5) !important;
}

.go_to_shopify_admin_div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.gtm_conv_label{
  display: block;
}
/* .main-conv-card-heading{
  ;
} */

.main-conv-card-p{
  color: #6c757d !important;
}
/* ---------------------------------------------------------- */
/* End Main  */
/* ---------------------------------------------------------- */


/* ---------------------------------------------------------- */
/* CustomCohortCharts */
/* ---------------------------------------------------------- */
.cohort-charts-main-container{
  background-color: white;
  padding: 30px;
  max-height: 30rem;
  overflow-y: scroll;
}

.cohort-charts-table{
  width: 100%;
}

.cohort-labels{
  width: 15%;
  font-size: medium;
}

.new-customer-col{
  width: 10%;
  font-weight: 600;
}

.cohort-values{
  width: 10%;
  text-align: center;
}

.cohort-repeat-per{
  width: 10%;
  font-weight: 600;
}

.cohort-ltv{
  width: 10%;
  font-weight: 600;
}

.cohort-charts-table-head-tr-th, .cohort-charts-table-body-tr-td{
  padding: 10px 0px;
}

.cohort-charts-table-head-tr-th{
  font-weight: 500 !important;
}

.cohort-charts-table-head{
  font-size: medium;
}

.cohort-value-shade-0{
  background: #f3f3f7;
  color: black;
  border: 1px solid white;
}
.cohort-value-shade-1{
  background: #f3f3f7;
  color: black;
  border: 1px solid white;
}
.cohort-value-shade-2{
  background: #cfd5e5;
  color: black;
  border: 1px solid white;
}
.cohort-value-shade-3{
  background: #a1abcd;
  color: white;
  border: 1px solid white;
}
.cohort-value-shade-4{
  background: #7081b1;
  color: white;
  border: 1px solid white;
}
.cohort-value-shade-5{
  background: #586ca3;
  color: white;
  border: 1px solid white;
}
/* ---------------------------------------------------------- */
/* End CustomCohortCharts */
/* ---------------------------------------------------------- */


/* ---------------------------------------------------------- */
/* Start LifetimeValue */
/* ---------------------------------------------------------- */
.charts_popup_filter_heading{
  font-weight:500;
  margin: 0;
}

.popup_filter_orders_option{
  padding-left: 0 !important;
}

.popup_filter_orders_option .css-13cymwt-control{
border-top-left-radius: 0 !important;
}

.popup_filter_options .css-13cymwt-control{
  border-top: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.popup_filter_orders_option .css-13cymwt-control, .popup_filter_include_exclude .css-13cymwt-control{
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* ---------------------------------------------------------- */
/* End LifetimeValue */
/* ---------------------------------------------------------- */


/* ---------------------------------------------------------- */
/* Start ChannelPerformance */
/* ---------------------------------------------------------- */
.cro-overview{
 background-color: white;
  margin: 0px 10px;
}

.cro_table_cell{
  font-size: 0.875rem;
  padding: 12px !important;
  /* padding: 10px !important; */
  border: none !important;
  border-bottom: 1px solid rgb(226, 226, 226) !important;
  text-align: left;
}

.cro_header_cell{
  background-color: #edf1f4;
  font-size: 1rem;
  padding: 12px !important;
  border: none !important;
  text-align: left;
}


.cro_table_row{
  background-color: white;
} 

.cro_old_table_row{
  /* background-color: rgb(247,246,246); */
  background-color: white;
}

.google-visualization-table-div-page{
  background-image: none !important;
  background-color: #edf1f4 !important;
}
/* ---------------------------------------------------------- */
/* End ChannelPerformance */
/* ---------------------------------------------------------- */

/* ---------------------------------------------------------- */
/* Start ChannelPerformance */
/* ---------------------------------------------------------- */

#existing_headline .google-visualization-table-tr-head, #existing_description .google-visualization-table-tr-head, #headline .google-visualization-table-tr-head, #description .google-visualization-table-tr-head{
  display: none;
}

.ad_copy_optimization_table_cell{
  width: 35% !important;
  font-size: 0.875rem;
  padding: 10px !important;
  border: none !important;
  border-bottom: 1px solid rgb(226, 226, 226) !important;
  text-align: left;
}

.ad_copy_optimization_header_cell{
  width: 35% !important;
  background-color: #edf1f4;
  font-size: 1rem;
  padding: 10px !important;
  padding-left: 0px !important;
  border: none !important;
  text-align: left;
}


.ad_copy_optimization_table_row{
  background-color: white;
} 

.ad_copy_optimization_old_table_row{
  /* background-color: rgb(247,246,246); */
  background-color: white;
}

.ad_copy_optimization_selected_table_row{
  background-color: rgba(17,44,123,.1) !important;
}

.ad_copy_optimization_hover_table_row{
  background-color: transparent;
}

.reports_table_cell, .ca_reports_table_cell{
  font-size: 0.875rem;
  padding: 10px 20px !important;
  border: none !important;
  border-bottom: 1px solid rgb(226, 226, 226) !important;
  text-align: center;
  min-width: 15rem;
}

.reports_header_cell, .ca_reports_header_cell{
  background-color: #edf1f4;
  font-size: 1rem;
  min-width: 15rem;
  padding: 10px !important;
  padding-top: 4rem !important;
  border: none !important;
  text-align: center;
}

.ca_reports_header_cell{
  min-width: 10rem;
  text-align: left;
  padding-top: 1rem !important;
  padding-left: 1rem !important;
}

.ca_reports_table_cell{
  text-align: left;
  padding-left: 1rem !important;
  min-height: 5rem !important;
}

.reports_table_row, .ca_reports_table_row{
  background-color: white;
} 

.reports_old_table_row, .ca_reports_old_table_row{
  /* background-color: rgb(247,246,246); */
  background-color: white;
}

.reports_selected_table_row, .ca_reports_selected_table_row{
  background-color: rgba(17,44,123,.1) !important;
}

.reports_hover_table_row, .ca_reports_hover_table_row{
  background-color: transparent;
}

.reports_table_cell:nth-child(1), .ca_reports_table_cell:nth-child(1){
  text-align: left;
} 


.meta_shopping_table_cell{
  font-size: 0.875rem;
  padding: 10px !important;
  border: none !important;
  border-bottom: 1px solid rgb(226, 226, 226) !important;
  text-align: left;
}

.meta_shopping_header_cell{
  background-color: #edf1f4;
  font-size: 1rem;
  padding: 10px !important;
  border: none !important;
  text-align: left;
}


.meta_shopping_table_row{
  background-color: white;
} 

.meta_shopping_old_table_row{
  /* background-color: rgb(247,246,246); */
  background-color: white;
}

.meta_shopping_selected_table_row{
  background-color: rgba(17,44,123,.1) !important;
}

.meta_shopping_hover_table_row{
  background-color: transparent;
}

.channel_performance_table_cell{
  font-size: 0.875rem;
  padding: 20px 30px !important;
  /* padding: 10px !important; */
  border: none !important;
  border-bottom: 1px solid rgb(226, 226, 226) !important;
  text-align: left;
}

.channel_performance_header_cell{
  background-color: #edf1f4;
  font-size: 1rem;
  padding: 30px 30px !important;
  border: none !important;
  text-align: left;
}


.channel_performance_table_row{
  background-color: white;
} 

.channel_performance_old_table_row{
  /* background-color: rgb(247,246,246); */
  background-color: white;
}

.google-visualization-table-div-page{
  background-image: none !important;
  background-color: #edf1f4 !important;
}

.metric-performance-charts-title{
  font-size: 1.135rem;
  font-weight: 600;
  margin-bottom: 5px;
}
/* ---------------------------------------------------------- */
/* End ChannelPerformance */
/* ---------------------------------------------------------- */


/* ---------------------------------------------------------- */
/* Start UTMPerformance */
/* ---------------------------------------------------------- */
#product-cataloging .accordion-button:focus,
#utm-performance-page .accordion-button:focus{
  box-shadow: none;
}
#product-cataloging .accordion-body,
#utm-performance-page .accordion-body{
  padding: 0px !important;
}
#product-cataloging .accordion-button:not(.collapsed), #product-cataloging .accordion-button,
#utm-performance-page .accordion-button:not(.collapsed), #utm-performance-page .accordion-button{
  background-color: white !important;
  color: black;
  font-weight: 300;
}
#utm-performance-page .channel_performance_header_cell:nth-child(4),
#utm-performance-page .channel_performance_header_cell:nth-child(5),
#utm-performance-page .channel_performance_header_cell:nth-child(6),
#utm-performance-page .channel_performance_header_cell:nth-child(7),
#utm-performance-page .channel_performance_old_table_row .channel_performance_table_cell:nth-child(4),
#utm-performance-page .channel_performance_old_table_row .channel_performance_table_cell:nth-child(5),
#utm-performance-page .channel_performance_old_table_row .channel_performance_table_cell:nth-child(6),
#utm-performance-page .channel_performance_old_table_row .channel_performance_table_cell:nth-child(7),
#utm-performance-page .channel_performance_table_row .channel_performance_table_cell:nth-child(4),
#utm-performance-page .channel_performance_table_row .channel_performance_table_cell:nth-child(5),
#utm-performance-page .channel_performance_table_row .channel_performance_table_cell:nth-child(6),
#utm-performance-page .channel_performance_table_row .channel_performance_table_cell:nth-child(7){
  background-color: #dfe1e572 !important;
  /* font-weight: bold; */
  text-align: center;
}
/* ---------------------------------------------------------- */
/* End UTMPerformance */
/* ---------------------------------------------------------- */


/* ---------------------------------------------------------- */
/* Start ProductJourney*/
/* ---------------------------------------------------------- */
.sankey-word-warp{
  word-wrap: break-word;
}
/* ---------------------------------------------------------- */
/* End ProductJourney */
/* ---------------------------------------------------------- */

.column_charts_spinner{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px !important;
  background-color: white;
  width: 100%;
}


.creative-generation-tag{
  position: absolute;
  box-shadow:5px 5px 10px rgba(17,44,123,.1);
  width: 10rem;
  font-size: 10px;
  text-align: center;
  top: -40px;
  right: -80px;
  background-color: rgb(27, 234, 4);
  color: white;
  font-weight: 600;
  padding: 5px 20px;
  transform: rotate(37deg);
  transform-origin: top left;
  z-index: 1;
}


.creative-generation-company-diagonal-tag{
  position: absolute;
  box-shadow:5px 5px 10px rgba(17,44,123,.1);
  width: 10rem;
  font-size: 10px;
  text-align: center;
  top: -40px;
  right: -80px;
  background-color: #ffcc00;
  color: white;
  font-weight: 600;
  padding: 5px 20px;
  transform: rotate(37deg);
  transform-origin: top left;
  z-index: 1;
}

.creative-generation-bp-diagonal-tag{
  position: absolute;
  box-shadow:5px 5px 10px rgba(17,44,123,.1);
  width: 10rem;
  font-size: 10px;
  text-align: center;
  top: -40px;
  right: -80px;
  background-color: rgb(27, 234, 4);
  color: white;
  font-weight: 600;
  padding: 5px 20px;
  transform: rotate(37deg);
  transform-origin: top left;
  z-index: 1;
}

.creative-generation-headline-tag{
  font-size: 10px;
  text-align: center;
  background-color: #ffcc00;
  border-top-right-radius: 1rem;
  color: white;
  font-weight: 600;
  padding: 5px 20px;
  z-index: 1;
}

.selected-product-box-shadow{
  border: 2px solid #000078 !important;
}

.selected-headline-border{
  /* background-color:#eaeaea; */
  border: 2px solid #000078 !important;
  box-shadow:none !important;
}

.product-selection-div{
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.product-div{
  width: 13rem;
  padding: 1rem;
}

.creative-generation-product-image{
  height:250px !important;
  width: 12rem;
  cursor:pointer !important; 
  position:relative !important; 
  overflow:hidden !important;
}

.creative-analysis-cards{
  height:100% !important;
  position:relative !important; 
  overflow:hidden !important;
  box-shadow: 8px 8px 5px rgba(17,44,123,.1);
}

.selected-headline-type-div{
  box-shadow: none !important;
  /* background-color: #eaeaea !important; */
  border: 2px solid #000078 !important;
}

.selected-creative-border{
  /* background-color:#eaeaea; */
  border: 2px solid #000078 !important;
  box-shadow:none !important;
}

.material-icons{
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

#report_name_input, #report_detail_input{
  border: none !important;
  outline: none !important;
  width: 100%;
  background-color: transparent; 
  font-size: xx-large;
}

#report_detail_input{
  font-size: large;
  color: #adadad;
}

#report_name_input:focus, #report_detail_input:focus{
  border: none !important;
  outline: none !important;
}

.custom-input-landing-page {
  flex-grow: 1;
  background-color: white;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.custom-input-landing-page:focus {
  outline: none;
}

.creative_ideation_theme_cards{
  cursor: pointer;
  border: 1px solid #cdcdcd;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 10rem !important;
  height: 3.5rem;
  max-height: 100%;
  text-align: center;
  justify-content: center;
  font-size: small;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(17, 44, 123, .1);
}


.creative_ideation_headline_cards{
  cursor: pointer;
  border: 1px solid #cdcdcd;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  height: 3.5rem;
  max-height: 100%;
  text-align: left;
  justify-content: space-between;
  font-size: small;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(17, 44, 123, .1);
}

.lp_optimization_keyword_status{
  display: inline-block;
  border: 1px solid rgb(205, 205, 205);
  background-color: white;
  width: 100%;
  border-radius: 5px;
  padding: 6px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* ------------------------------------- */
/* Google and meta Integration Accordion */
/* ------------------------------------- */
.google_and_meta_integration_accordion_container{
  max-width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.google_and_meta_integration_status_col{
  justify-items: end !important;
}
/* ------------------------------------- */


/* ------------------------------------- */
/* Login Left side Partition             */
/* ------------------------------------- */
.group_accordion_container{
  margin: 0 !important;
}
/* ------------------------------------- */

/* ------------------------------------- */
/* Login Left side Partition             */
/* ------------------------------------- */

.login-main-container{
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}
.login-details-part{
  display: flex;
  width: 50vw;
  justify-content: center;
  align-items: center;
  background-color: rgb(19, 40, 72);
}
.login-form-part{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
}
.login-form-div{
  width: 60%; 
  background-color: white;
  box-shadow: 10px 15px 15px rgba(17, 44, 123, .1); 
  border: 2px solid grey; 
  border-radius: 0.5rem; 
  padding: 5%;
}
/* ------------------------------------- */

/* ---------------------------------------------------------- */
/* Medium <= 992px */
/* ---------------------------------------------------------- */
@media screen and (max-width: 992px) {
  
  .login-form-div{
    width: 70%; 
  }

  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-customer{
    margin-top: 15px;
  }

  #top-bottom-border{
    border-top: none;
  }

  #sidebar-title-responsive{
    display: none;
  }

  .sidebar-title > span {
    display: inline;
  }

  .header{
    display: flex !important;
  }
}
/* Small <= 768px */
  
@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }

  .login-main-container{
    display: block;
  }
  .login-details-part{
    width: 100vw;
    height: 15vh;
  }
  .login-form-part{
    width: 100vw;
    height: 85vh;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}
