::-webkit-scrollbar {
  width: 10px;
  display: none;
}
/* 
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox */
/* * {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}*/


/* Dark Theme Styling */
/*
.dark-theme {
  background-color: #1e1e1e;
  color: #ffffff;
} */

.chat-container {
  max-width: 100%;
  /* margin: 25px auto; */
  border-radius: 10px;
  padding: 20px;
  background-color: transparent;
}

.chat-window {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.chat-history {
  max-height: 64vh;
  width: 65%;
  overflow-y: scroll;
  margin-bottom: 20px;
  padding: 10px;
}

.message {
  margin-bottom: 25px;
}

.message-bar-right{ 
  display: flex;
  justify-content: right;
}

.message-bar-left{
  display: flex;
  justify-content: left;
}

.user-message {
  max-width: 30vw;
  width: fit-content;
  text-wrap: wrap;
  /* background-color: #f6f6f6; */
  background-color: white;
  color: black;
  text-align: left;
  padding: 5px 20px;
  border-radius: 1rem;
  display: flex;
  justify-content: right;
  font-family: Inter, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.ai-message {
  max-width: 65%;
  text-wrap: wrap;
  width: fit-content;
  /* background-color: #f6f6f6; */
  background-color: white;
  color: black;
  text-align: left;
  padding: 5px 20px;
  border-radius: 1rem;
  display: flex;
  justify-content: left;
  font-family: Inter, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.feedback-icons {
  font-size: 12px;
  margin-left: 10px;
  display: flex;
  gap: 5px;
}

.feedback-icons span {
  cursor: pointer;
  color: #aaa;
}

.feedback-icons span:hover {
  color: #fff;
}

.input-form-main-div{
  position: absolute;
  left: 2vw;
  right: 2vw;
  bottom: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-form{
  width: 65%;
}

.input-area {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5rem;
  padding: 0.5rem 1.5rem;
}

.input-area input {
  flex-grow: 1;
  background-color: white;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.input-area input:focus {
  outline: none;
}

.input-area button {
  background-color: white;
  border: none;
  padding: 5px;
  margin-left: 10px;
  border-radius: 5px;
}

.max-w-95{
  max-width: 95% !important;
}

@media only screen and (max-width: 992px) {
  .input-form{
    width: 97%;
  }
  .chat-history {
    width: 97%;
  }
}

.font-size-smaller{
  font-size: smaller !important;
}

.communication_ai_label{
  color: #adadad;
  font-weight: 600;
  font-size: 1rem;
}

.chatbot_filter{
  background-color: rgb(19, 40, 72);
  width: 15%;
  padding: 0.5rem;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 100;
}


@media screen and (min-width: 800px) and (max-width: 1100px) {
  .chatbot_filter{
    width: 25%;
  }
}

@media screen and (min-width: 500px) and (max-width: 800px) {
  .chatbot_filter{
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .chatbot_filter{
    width: 75%;
  }
}